<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Please arrange each of the following steps in the correct order for the procedure for Part A
        of this experiment.
      </p>

      <drag-to-rank-input
        v-model="inputs.inputs"
        :items="choicesShuffled"
        class="mb-0"
        style="max-width: 631px"
        prepend-text="First Step"
        append-text="Last Step"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemMcM1A03_A3_FlowChartA',
  components: {
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputs: [],
      },
      choices: [
        'Collect KSCN in a burette',
        'Rinse volumetric flasks with iron(III) nitrate',
        'Add KSCN from burette to each of the 3 volumetric flasks',
        'Dilute each solution to 50.00 mL with iron(III) nitrate',
        'Invert each volumetric flask several times to mix the solutions',
        'Blank the spectrophotometer with DI water sample',
        'Rinse a cuvette multiple times with solution to be studied',
        'Fill the cuvette with the solution to be studied',
        'Wipe the sides of the cuvette with a KimWipe',
        'Measure the absorption of the sample',
        'Repeat previous 4 steps for other two solutions',
        'Prepare a calibration curve',
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id ?? 1;
    },
    choicesShuffled() {
      return seededShuffle(this.choices, this.seed);
    },
  },
};
</script>
